import { Link } from "react-router-dom"

const Prototype = () => {
	const sendmsg = () => {
		window.open("sms:17868347988");
	}
	return (
		<div>
			<div className={"pt-[75.45px] md:pt-[60px] px-5 md:px-0"}>
				<img className="hidden md:block absolute top-0 left-0 w-full h-full object-cover" src="/images/prototype/back.png" alt="" />
				<img className="block md:hidden absolute top-0 left-0 w-full h-full object-cover" src="/images/prototype/back-mobile.png" alt="" />
				<div className="flex flex-col items-center relative">
					<span className="font-semibold text-[16px] leading-[19.09px]">Introducing</span>
					<div className="flex items-center space-x-[9.02px] mt-1">
						<img className="w-[45.12px] h-[45.12px] rounded-full" src="/images/prototype/jessie.png" alt="" />
						<span className="font-semibold text-[31.59px] leading-[37.69px]">Jessie Sims</span>
						<img src="/images/prototype/verified.svg" alt="" />
					</div>
					<span className="mt-[15px] text-[14px] leading-[21px] text-center">Hello Love! I can't wait to have an intimate conversation with you, let's chat! 💋</span>
					<button className="flex justify-center items-center space-x-[15px] px-[30px] py-[7px] md:py-[11.57px] rounded-[5px] font-semibold text-[16px] leading-[19.09px] bg-gradient5 mt-[15px]" onClick={sendmsg}>
						<img className="w-[30px]" src="/images/prototype/text.png" alt="" />
						<span>Text me on iMessage</span>
					</button>
					<img className="hidden md:block mt-[60px] w-[543.75px]" src="/images/prototype/phone.png" alt="" />
					<img className="block md:hidden mt-[74.58px] w-[335px]" src="/images/prototype/phone-mobile.png" alt="" />
				</div>
			</div>
			<div className="flex flex-col items-center bg-black2 border border-black5 py-[15px] md:pt-[19px] md:pb-[18.28px] absolute bottom-0 left-0 w-full">
				<div className="flex items-center space-x-[4.46px]">
					<img className="w-[29px] h-[29px]" src="/images/prototype/jessie.png" alt="" />
					<span className="font-semibold text-[15.62px] leading-[18.63px]">Jessie Sims</span>
					<img src="/images/prototype/verified-white.svg" alt="" />
				</div>
				<span className="mt-[6.72px] text-[12px] leading-[18px] text-black11 capitalize">Let's have an immersive conversation.</span>
				<div className="flex items-center space-x-[30px] mt-[15px] text-[12px] leading-[14.32px] text-black11">
					<Link to="/terms">Term & Conditions</Link>
					<a>Disclaimers</a>
				</div>
			</div>
		</div>
	)
}

export default Prototype