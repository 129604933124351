import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
}

export const AuthReducer = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setProfile: (state, action) => {
            state.profile = action.payload
        },
    },
})

export const { setProfile } = AuthReducer.actions

export default AuthReducer.reducer