import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
    openSignModal: false,
    openBioModal: false,
    openConfettiModal: false,
    openShareModal: false,
    details: {},
}

export const ModalReducer = createSlice({
    name: 'modal',
    initialState,
    reducers: {
        setOpenSignModal: (state, action) => {
            state.openSignModal = !state.openSignModal
        },
        setOpenBioModal: (state, action) => {
            state.referral = action.payload
            state.openBioModal = !state.openBioModal
        },
        setOpenShareModal: (state, action) => {
            state.openShareModal = !state.openShareModal
        },
        setOpenConfettiModal: (state, action) => {
            state.openConfettiModal = !state.openConfettiModal
        },
        setDetails: (state, action) => {
            state.details = action.payload
        },
        setReferralDopple: (state, action) => {
            state.referral = action.payload
        }
    },
})

export const { setOpenSignModal, setOpenBioModal, setOpenShareModal, setOpenConfettiModal, setDetails, setReferralDopple } = ModalReducer.actions

export default ModalReducer.reducer